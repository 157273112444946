import moment from 'moment';
import { AuctionFlavor } from '@/utils/constants';

/**
 * filter auctions by code, name or description for the current language
 * @param {string} search - The search string
 * @param {any} auctions - The auctions array
 * @param {string} activeLang - The currently active language in 2-digit code i.e. 'en'
 * @return {<any>} - Filtered auctions
 */
export const searchAuctions = (search: string, auctions: any, activeLang: string) => {
  const result = auctions.filter(el => (el.code && el.code.toString().includes(search)) ||
    (el.name && el.name.toLowerCase().includes(search.toLowerCase())) ||
    (el.name_i18n && el.name_i18n[activeLang] && el.name_i18n[activeLang].toLowerCase().includes(search.toLowerCase())) ||
    (el.description_i18n && el.description_i18n[activeLang] && el.description_i18n[activeLang].toLowerCase().includes(search.toLowerCase())) ||
    (el.description && el.description.toLowerCase().includes(search.toLowerCase())))
  return result;
}

/**
 * sort auctions according to start_at descending (the newest one is always on top). Sales are shown below live/timed auctions but only if they are started
 * @param {any[]} events - The auctions
 * @return {<any>} - Sorted auctions
 */
export const sortAuctions = (events: any[]) => {
  const sortFunction = (a, b) => {
    const aTime = moment(a.start_at) as any
    const bTime = moment(b.start_at) as any
    return bTime - aTime
  }

  let result = [];
  const sales = events.filter(e => e.type === AuctionFlavor.sale);
  const timedLive = events.filter(e => e.type !== AuctionFlavor.sale);
  sales.sort(sortFunction);
  timedLive.sort(sortFunction);
  result.push(...timedLive);
  result.push(...sales);

  return result;
}
