import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import { SocketNamespace } from '@/utils/constants';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';

const socketRequests = {
  getUserBidderNumbers: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId
      }
      socket.emit('getUserBidderNumbers', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getUserProfile: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: 'me'
      }
      socket.emit('getUserProfile', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  updateUserProfile: async (userId: number, userData: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: userData.id,
        userData
      }
      socket.emit('updateUserProfile', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getAuctions', {}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  requestSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        auctionId
      };
      if (bidLimit !== null) {
        payload.signup_data = {
          bidLimit
        };
      }
      if (receiveStartReminderEmail !== undefined) {
        payload.signup_data = {
          ...payload.signup_data,
          notificationAuctionStart: receiveStartReminderEmail
        }
      }
      socket.emit('requestAuctionSignup', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sendEmailInquiry: async (userId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        email_data: data,
      };
      socket.emit('sendInquiryMail', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getWishlist: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  addToWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('addToUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  removeFromWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('deleteFromUserWishlist', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId: 'all'
      };
      if (getUserData) {
        payload.getUserData = true
      }
      socket.emit('getArticleCommissionBids', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  setCommissionBids: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        commission_bids: data.commission_bids
      };
      socket.emit('placeArticleCommissionBids', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserKnockdownsPerAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('getUserKnockdownsPerAuctionArticleSlider', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  searchSocketArticles: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getArticlesByQuery', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  validateCommissionBids: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('validateCommissionBids', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sendMessageOnSocket: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('sendMessage', data, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  getCurrentActiveAuction: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getActiveAuctionState', {}, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  doSocketBid: async (auctionId: number, articleId: number, bid: number, isUser: boolean, isBtr: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      //console.log(JSON.stringify(useAuctionStore().currentAuction));
      const socketName = isUser ? SocketNamespace.users : SocketNamespace.admins;
      const { socket } = SocketClient.getInstance(socketName);
      const payload: any = {
        auctionId,
        articleId,
        bid,
        isBtr,
        timestamp: Date.now()
      };
      socket.emit('liveBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId
      };
      socket.emit('jumpToArticle', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  undoBid: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('undoBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  askBid: async (auctionId: number, value: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        value
      };
      socket.emit('askBid', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  bidWarning: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('bidWarning', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  updateExternalBidderNumber: async (auctionId: number, bidderNumber: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        bidderNumber
      };
      socket.emit('updateExternalBidderNumber', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  sellItem: async (auctionId: number, bidderNumber: number, conditional: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      let payload: any = {
        auctionId,
        conditional
      };
      if (bidderNumber !== null) {
        payload.bidderNumber = bidderNumber;
      }
      socket.emit('sellItem', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  joinLiveAuction: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId: auctionId
      };
      socket.emit('joinAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    }),
  leaveLiveAuction: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId: auctionId
      };
      socket.emit('leaveAuction', payload, (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            Sentry.setContext('socket error data', {
              data: JSON.stringify(response.data)
            });
          }
          Sentry.captureException(new Error(response));
          reject(response);
        }
      })
    })
}

export default socketRequests;
