import { i18n } from '@/main';

/**
 * Check a password, if it matches 3 out of 4 criteria
 * @param {string} str - The password
 * @return {boolean} - True if it suceeds, false if not
 */
export function validatePassword(str: string): boolean {
  let validateConditions = 0

  if (str.length < 8) {
    return false;
  }

  if (str.match(/[A-Z]/)) {
    validateConditions++;
  }

  if (str.match(/[a-z]/)) {
    validateConditions++;
  }

  if (str.match(/[\d]/)) {
    validateConditions++;
  }

  if (str.match(/[@$!%*#?&]/)) {
    validateConditions++;
  }
  return validateConditions >= 3
}

/**
 * Check a password, returns an object indicating the strngth score.
 * @param {string} password - The password
 * @return {Object} - The strength score object
 */
export function passwordStrength(password: string): object {
  if (!password || password.length <= 0) {
    return {
      color: 'red',
      value: 0
    }
  }
  const result = {
    score: 4
  }

  if (result.score === 4 && validatePassword(password)) {
    return {
      color: 'light-blue',
      value: 100
    }
  } else if (result.score >= 2 && validatePassword(password)) {
    return {
      color: 'light-green',
      value: 75
    }
  } else if (result.score >= 2) {
    return {
      color: 'yellow',
      value: 50
    }
  } else if (result.score === 1) {
    return {
      color: 'orange',
      value: 25
    }
  } else {
    return {
      color: 'red',
      value: 0
    }
  }
}

/**
 * create the object for Sentry.js and strip sensitive information
 * @param {any} object - The source object
 * @return {string} - The object (json)
 */
export function createSentryData(object: any): string {
  let result: string;
  if (object.data === undefined) {
    result = JSON.stringify(object);
  } else {
    let o = { ...object }
    o.data = { ...object.data }
    const filterList: string[] = [
      'password',
      'old_password',
      'new_password',
      'token'
    ];

    for (let key of filterList) {
      if (o.data[key] !== undefined) {
        o.data[key] = '***';
      }
    }

    result = JSON.stringify(o);
  }

  return result;
}

/**
 * sort languages alphabetically inplace
 * @param {any[]} languages - The languages
 * @return {<void>} - Nothing
 */
export const sortLanguages = (languages:any[]): void => {
  languages.sort((e1, e2) => e1.name.localeCompare (e2.name));
}
