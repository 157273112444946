import io from 'socket.io-client'
import { SocketConnectionStatus, SocketDisconnectReason, SocketNamespace } from '@/utils/constants';
import SocketHandlers from '@/api/socketHandlers';
import useRootStore from '@/stores/rootStore';
import { getAuthToken } from '@/utils/token';

let timeoutIdx: any

class SocketClient {
  private static instances: any = {};
  public socket: any;

  private constructor(namespace: SocketNamespace) {
    const rootStore = useRootStore();
    console.log(`socket.io: constructed socket instance with namespace ${namespace}`)
    this.socket = io(`${process.env.VUE_APP_SERVER}/${namespace}`, {
      // transports: ["websocket"],
      query: { token: getAuthToken() }
    });
    rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnected)
    this.socket.customConnectionStatus = 'disconnected'
    this.socket.on('connect', () => {
      console.log('socket.io: connected to socket successfully');
      rootStore.SET_OFFLINE_STATUS(false);
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.connected)
      rootStore.SET_SOCKET_DISCONNECT_STATUS(SocketDisconnectReason.notDisconnected)

      // var onevent = this.socket.onevent
      // this.socket.onevent = function(packet: any) {
      //   var args = packet.data || [];
      //   onevent.call (this, packet);    // original call
      //   packet.data = ["*"].concat(args);
      //   onevent.call(this, packet);
      // }

      // this.socket.on("*",function(event:any,data:any) {
      //   console.log(event);
      //   console.log(data);
      // });
    })
    this.socket.on('error', (error: any) => {
      console.log(`socket.io: got error ${error}`);
    });

    this.socket.on('reconnect', (attempt: any) => {
      console.log(`socket.io: Reconnected on attempt ${attempt}`)
      rootStore.SET_OFFLINE_STATUS(false)
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.connectedReConnected)
      rootStore.SET_SOCKET_DISCONNECT_STATUS(SocketDisconnectReason.notDisconnected)
    });

    this.socket.on('reconnect_attempt', (attempt: any) => {
      console.log(`socket.io: Attempting to reconnect, attempt ${attempt}`)
      rootStore.SET_OFFLINE_STATUS(true)
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnectedDisconnecting)
    });

    this.socket.on('reconnect_error', (error: any) => {
      console.log(`socket.io: Reconnect error, ${error}`)
      rootStore.SET_OFFLINE_STATUS(true)
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnectedReconnectionError)
    });

    this.socket.on('reconnect_failed', (error: any) => {
      console.log(`socket.io: Failed to reconnect error is ${error}`)
      rootStore.SET_OFFLINE_STATUS(true)
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnectedReconnectingFailed)
    });

    this.socket.on('force-logout', () => {
      console.log('socket.io: Client connection has been forcefully terminated by the backend')
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnected)
      rootStore.logout()
    })

    this.socket.on('disconnect', (reason: any) => {
      console.log(`socket.io: Disconnected, reason is ${reason}`)
      if (!rootStore.isLogoutClicked) rootStore.SET_OFFLINE_STATUS(true)
      if (!rootStore.isLogoutClicked) rootStore.SET_SOCKET_DISCONNECT_STATUS(reason)
      rootStore.SET_SOCKET_CONNECTION_STATUS(SocketConnectionStatus.disconnected) //TODO predicate not exist in auction admin
    });

    // Is not shown on any client, see https://stackoverflow.com/questions/30207156/observe-the-ping-of-socketio-client-at-server-side
    this.socket.on('ping', () => {
      console.log('socket.io: Ping received')
    });

    SocketHandlers.loadHandlers(namespace, this.socket);
  }

  /**
   * Get the current socket.io instance or create one if none exists
   * @param {SocketNamespace} namespace - socket namespace (if omitted, it will be calculated based on the user role)
   * @return {SocketClient} - socket instance
   */
  public static getInstance(namespace?: SocketNamespace): SocketClient {
    const rootStore = useRootStore();
    if (namespace === undefined) {
      namespace = (rootStore.userProfile && rootStore.userProfile.role === 'admin') ? SocketNamespace.admins : SocketNamespace.users;
    }

    if (!SocketClient.instances[namespace]) {
      SocketClient.instances[namespace] = new SocketClient(namespace);
    }
    return SocketClient.instances[namespace];
  }

  /**
   * Remove the socket.io instance
   * @return {SocketClient} - socket instance
   */
  public static removeInstance(): void {
    for (const key of Object.keys(SocketClient.instances)) {
      console.log(`socket.io: removed socket instance ${key}`)
      delete SocketClient.instances[key];
    }
  }
}

export default SocketClient
