<template>
  <v-dialog max-width="680" v-model="joinModal" @click:outside="CHANGE_STATE({ key: 'joinModal', value: false })">
    <v-card-title class="headline">
      <span class="headline">{{ $t('Do you want to join the live auction?', {tAuctionId: currentAuction.id}) }}</span>
    </v-card-title>
    <v-card-text>{{ $t('joinModal.text', {tcurrentAuctionCode: currentAuction ? currentAuction.code : ""}) }}
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" @click="cancelModal">{{ $t('No') }}</v-btn>
      <v-btn class="text-none" color="success" @click="submitModal">{{ $t('Yes') }}</v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';

export default {
  name: 'JoinAuction',
  props: {},
  data: () => ({}),
  computed: {
    ...mapState(useAuctionStore, ['joinModal', 'currentAuction'])
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'joinAuction', 'leaveAuction', 'SET_TEMP_ALERT']),
    ...mapActions(useAuctionStore, ['CHANGE_STATE']),
    async submitModal() {
      try {
        await this.joinAuction(this.currentAuction.id)

        if (this.$route.name === 'dashboard') {
          this.UPDATE_GLOBAL_STATE({key: 'showAuctionOverview', value: true})
        } else {
          this.$router.push({ name: 'dashboard' })
        }
        this.UPDATE_GLOBAL_STATE({ key: 'showAuctionOverview', value: false })
        this.CHANGE_STATE({ key: 'joinModal', value: false })
      } catch (e) {
        console.log(e)
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error joining the auction. Please try again later') })
      }
    },
    async cancelModal() {
      await this.leaveAuction(this.currentAuction.id, true) // exception handler inside
      this.CHANGE_STATE({ key: 'joinModal', value: false })
    }
  }
}
</script>
<style lang="scss" scoped>
.primary-btn,
.secondary-btn {
  @media screen and (max-width: 968px) {
    max-width: calc(50% - 16px) !important;
    min-width: initial !important;
  }
}
</style>
