import { SocketConnectionStatus } from '@/utils/constants';
import socketRequests from '@/api/socketRequests';
import apiRequests from '@/api/apiRequests';
import useRootStore from '@/stores/rootStore';

const dispatcher = {
  /**
   * Search for articles
   * @return {Promise<any>} - All found articles
   */
  searchArticles: async (query: string, language: string): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.searchSocketArticles({ query, lang: language });
    } else {
      result = await apiRequests.searchArticles(query, language);
    }

    return result;
  },
  /**
   * Get all auctions
   * @return {Promise<any>} - All auctions
   */
  getAllAuctions: async (): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getAuctions();
    } else {
      result = await apiRequests.getAuctions();
    }

    return result;
  },
  /**
   * Request auction signup
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} [bidLimit] - The bid limit
   * @param {boolean} [receiveStartReminderEmail] - Flag to control if the user wants a reminder email before the auction starts
   * @return {Promise<void>} - Nothing
   */
  requestAuctionSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> => {
    const rootStore = useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      if (bidLimit !== undefined) {
        await socketRequests.requestSignup(auctionId, userId, bidLimit, receiveStartReminderEmail)
      } else {
        await socketRequests.requestSignup(auctionId, userId, null, receiveStartReminderEmail);
      }
    } else {
      if (bidLimit !== undefined) {
        await apiRequests.requestSignup(auctionId, userId, bidLimit, receiveStartReminderEmail);
      } else {
        await apiRequests.requestSignup(auctionId, userId, null, receiveStartReminderEmail);
      }
    }
  },
  /**
   * Send an inquiry (general, article or post-auction-sale related)
   * @return {Promise<void>} - Nothing
   */
  sendInquiry: async (userId: number, data: any): Promise<any> => {
    const rootStore = useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      await socketRequests.sendEmailInquiry(userId, data);
    } else {
      await apiRequests.sendEmailInquiry(userId, data);
    }
  },
  /**
   * Validate commission bids
   * @param {number} auctionId - The auction id
   * @param {any} data - Commission bid data
   * @return {Promise<any>} - Promise containing the validation result
   */
  validateCommissionBids: async (auctionId: number, data: any): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.validateCommissionBids(data);
    } else {
      result = await apiRequests.validateCommissionBids(auctionId, data);
    }

    return result;
  },
  /**
   * Get all wishlist entries for a user
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the wishlist entries
   */
  getWishlist: async (auctionId: number, userId: number): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getWishlist(auctionId, userId);
    } else {
      result = await apiRequests.getWishList(auctionId, userId);
    }

    return result;
  },
  /**
   * Add an article to the wishlist
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} articleId - The article id
   * @return {Promise<void>} - Promise containing nothing
   */
  addToWishlist: async (auctionId: number, userId: number, articleId: number): Promise<void> => {
    const rootStore = useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      await socketRequests.addToWishlist(auctionId, userId, articleId);
    } else {
      await apiRequests.addToWishList(auctionId, userId, articleId);
    }
  },
  /**
   * Remove an article from the wishlist
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @param {number} articleId - The article id
   * @return {Promise<void>} - Promise containing nothing
   */
  removeFromWishlist: async (auctionId: number, userId: number, articleId: number): Promise<void> => {
    const rootStore = useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      await socketRequests.removeFromWishlist(auctionId, userId, articleId);
    } else {
      await apiRequests.removeFromWishList(auctionId, userId, articleId);
    }
  },
  /**
   * Get all commission bids for a user
   * @param {number} auctionId - The auction id
   * @param {boolean} getUserData - Flag to control if user data should be retrieved
   * @return {Promise<any>} - Promise containing the commission bids
   */
  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getAllCommissionBids(auctionId, getUserData)
    } else {
      result = await apiRequests.getAllCommissionBids(auctionId, getUserData);
    }

    return result;
  },
  /**
   * Place one o more commission bids for a user
   * @param {number} auctionId - The auction id
   * @param {array} commissionBids - The array of commission bids
   * @return {Promise<any>} - Promise containing the placed commission bids
   */
  placeCommissionBids: async (auctionId: number, commissionBids: any): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.setCommissionBids(auctionId, commissionBids)
    } else {
      result = await apiRequests.setCommissionBids(auctionId, commissionBids);
    }

    return result;
  },
  /**
   * Get all knockdowns for a user
   * @param {number} auctionId - The auction id
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the knockdowns
   */
  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getUserKnockdowns(auctionId, userId)
    } else {
      result = await apiRequests.getUserKnockdowns(auctionId, userId);
    }

    return result;
  },
  /**
   * Get all knockdowns for the article slider for a user and the admin
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the knockdowns
   */
  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getKnockdownsArticleSlider(auctionId)
    } else {
      result = await apiRequests.getKnockdownsArticleSlider(auctionId);
    }

    return result;
  },
  /**
   * Get biddernumbers for a user sorted by id ascending
   * @param {number} userId - The user id
   * @return {Promise<any>} - Promise containing the bidder numbers
   */
  getBidderNumbers: async (userId: number): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getUserBidderNumbers(userId);
    } else {
      result = await apiRequests.getUserBidderNumbers(userId);
    }

    return result;
  },
  /**
   * Get the current active live auction
   * @return {Promise<any>} - Promise containing the current active auction or an empty object if none is running
   */
  getCurrentActiveAuction: async (): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.getCurrentActiveAuction();
    } else {
      result = await apiRequests.getCurrentActiveAuction();
    }

    return result;
  },
  /**
   * Jump to an article in a live auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the current active auction or an empty object if none is running
   */
  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> => {
    const rootStore = useRootStore();
    let result;
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.appFeatures.webSocketAuctionStateManagement && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      result = await socketRequests.jumpToArticle(auctionId, articleId);
    } else {
      result = await apiRequests.jumpToArticle(auctionId, articleId);
    }

    return result;
  },

  /**
   * Join a live auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  joinLiveAuction: async (auctionId: number): Promise<void> => {
    const rootStore= useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      await socketRequests.joinLiveAuction(auctionId);
    } else {
      await apiRequests.joinLiveAuction(auctionId);
    }
  },

  /**
   * Leave a live auction
   * @param {number} auctionId - The auction id
   * @return {Promise<any>} - Promise containing the result
   */
  leaveLiveAuction: async (auctionId: number): Promise<void> => {
  const rootStore= useRootStore();
    if (rootStore.appFeatures && rootStore.appFeatures.webSocketFunctionality && rootStore.socketConnectionStatus === SocketConnectionStatus.connected) {
      await socketRequests.leaveLiveAuction(auctionId);
    } else {
      await apiRequests.leaveLiveAuction(auctionId);
    }
  }
}

export default dispatcher;
