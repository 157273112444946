import { defineStore } from 'pinia'
import { IArticle, ICategory, IClientListEntry, IUser } from '@/api/types';
import configs from '../configs'
import _, { isArray } from 'lodash';
import {
  localFeatures,
  SocketConnectionStatus,
  SocketDisconnectReason,
  timeouts
} from '@/utils/constants';
import Vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import useAuctionStore from '@/stores/auctionStore';
import apiRequests from '@/api/apiRequests';
import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import socketRequests from '@/api/socketRequests';
import { i18n } from '@/main';
import moment from 'moment/moment';
import { checkIfTokenIsValid, getAuthToken, removeAuthToken } from '@/utils/token';
import dispatcher from '@/api/dispatch';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';

interface IRootStoreState {
  socket: Record<string, any>,
  loading: boolean,
  appLoading: boolean,
  userProfile: IUser,
  token: string,
  error: boolean,
  errorMessage: string,
  notificationList: Array<any> // eslint-disable-line
  showBottomNavigation: boolean,
  showEditProfileModal: boolean,
  showKnockdownsModal: boolean,
  showProductCatalogue: boolean,
  productCataloguePredefinedValue: boolean,
  showAuctionOverview: boolean,
  showAuctionModal: boolean,
  showAdminKnockdownsModal: boolean,
  appSettings: Record<string, any>,
  streamSettings: Record<string, any>,
  appLocalization: Record<string, any>,
  appFeatures: Record<string, any>
  clientList: Array<IClientListEntry>
  realtimeClientData: any,
  appStoreLang: string,
  showArticlesList: boolean,
  cookiesStatus: boolean,
  auctionsFetchedArticles: Array<IArticle>
  isMobile: boolean,
  loginButtonDisabled: boolean,
  categories: Array<ICategory>
  alert: Array<Record<string, any>>
  sendMessageModal: boolean,
  disconnectedTime: number,
  adminDataChange: boolean,
  users: Array<IUser>,
  adminCategories: Array<ICategory>
  adminArticles: Array<IArticle>,
  isOffline: boolean
  isLogoutClicked: boolean,
  globalTheme: string,
  unexpectedError: string,
  adminDrawer: boolean,
  socketConnectionStatus: SocketConnectionStatus,
  socketDisconnectReason: SocketDisconnectReason,
  auctionJoined: number,
  preselectionData: IPreselectionData,
  showTfaDialog: boolean
}

interface IPreselectionData {
  selectedAuctionId: number // selected auction id through the auction selector for i.e. product catalogue
}

const useRootStore = defineStore('rootStore', {
  state (): IRootStoreState {
    return {
      socket: {
        customConnectionStatus: 'init'
      },
      loading: false, // app loading overlay
      appLoading: false, // flag to be able to determine if api or socket requests are currently ongoing
      userProfile: {} as IUser, // Current user profile
      token: '', // jwt (not used, can be removed)
      error: false,
      errorMessage: '',
      notificationList: [], // notifications (not used)
      showBottomNavigation: true, // Show bottom mobile notification bar
      showEditProfileModal: false, // Show user edit profile modal
      showKnockdownsModal: false, // Show user knockdowns profile modal
      showProductCatalogue: false, // Show user product catalogue
      productCataloguePredefinedValue: false,
      showAuctionOverview: true, // Show user auction overview
      showAuctionModal: false, // Show user knockdowns profile modal
      showAdminKnockdownsModal: false,
      appSettings: {}, // general app settings
      streamSettings: {}, // streaming settings
      appLocalization: {}, // localization settings
      appFeatures: {}, // app features
      clientList: [], // socket client list
      realtimeClientData: {}, // socket client data
      appStoreLang: '', // the current ui language
      showArticlesList: false,
      cookiesStatus: window.localStorage.getItem('vue-cookie-accept-decline-cookies') === 'accept', // if cookies are accepted by the user
      auctionsFetchedArticles: [], // fetched articles from an auction for product catalogue etc. (not for a running live auction)
      isMobile: window.innerWidth <= 968, // Flag which is set for mobile devices
      loginButtonDisabled: false, // Flag which disables the login button, used when restoring the config or db as the backend needs to restart
      categories: [], // fetched categories for the user frontend
      alert: [], // array of messages (success, error or messages sent by the admin)e either set by SET_ALERT or by SET_TEMP_ALERT
      sendMessageModal: false,
      disconnectedTime: 0, // socket disconnected time
      adminDataChange: null,
      users: [], // fetched users for the admin frontend
      adminCategories: [], // fetched categories for the user frontend
      adminArticles: [], // fetched articles for the admin frontend (not used?)
      isOffline: false, // app offline status (socket), can take up to 15s to detect if no internet
      isLogoutClicked: false, // Flag which is set during logout (if the user clicked the button)
      globalTheme: configs.theme.globalTheme,
      unexpectedError: '',
      adminDrawer: true,
      socketConnectionStatus: SocketConnectionStatus.init, // socket connection status
      socketDisconnectReason: SocketDisconnectReason.notDisconnected, // socket disconnect reason (see https://socket.io/docs/v4/client-api/#event-disconnect)
      auctionJoined: null, // auction id of the joined live auction, will be set to null on logout or kickout (this only updates if the user clicks on "yes" in the modal. It can be
      // that the user is already joined to the room
      preselectionData: {selectedAuctionId: null}, // selected auction id through the auction selector for i.e. product catalogue
      showTfaDialog: false // flag to control if the tfa dialog should be shown
    }
  },
  getters: {
    isAuthenticatedAsUser (state: any) {
      return !_.isEmpty(state.userProfile)
    }
  },
  actions: {
    UPDATE_GLOBAL_STATE(object: any) { // TODO use types
      // Array
      if (isArray(object) && object.length > 0) {
        object.forEach((item: any) => {
          this[item.key] = item.value
        })
      } else {
        this[object.key] = object.value
      }
    },
    APP_GLOBAL_STORE_LANG (lang:string) {
      this.appStoreLang = lang
    },
    SET_IS_MOBILE () {
      this.isMobile = window.innerWidth <= 968
    },
    SET_LOGIN_BUTTON_DISABLED (data: any) {
      this.loginButtonDisabled = data
    },
    SET_AUCTIONS_FETCHED_ARTICLES (payload: any) {
      Vue.set(this.auctionsFetchedArticles, `auction_${payload.auctionID}`, payload.articles)
      // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
      GlobalEvents.emitEvent(GlobalEventType.updateArticle)
    },
    UPDATE_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1, {
          ...this.auctionsFetchedArticles[`auction_${payload.auctionID}`][idx],
          ...payload.article
        })
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    REMOVE_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    ADD_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].push(payload.article)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    SET_CATEGORIES (categories: any) {
      this.categories = categories
    },
    ADD_CATEGORY (payload: any) {
      this.categories.push(payload)
    },
    REMOVE_CATEGORY (payload: any) {
      const idx = this.categories.findIndex((el: any) => el.id === payload.id)
      this.categories.splice(idx, 1)
    },
    SET_ALERT (data: any) {
      this.alert = [data]
    },
    SET_TEMP_ALERT (data: any) {
      this.alert.push(data)
      setTimeout(() => {
        const index = this.alert.indexOf(data);
        if (index !== -1) {
          this.alert.splice(index, 1);
        }
      }, data.timeout || timeouts.closeToast)
    },

    CLEAR_ALERT () {
      this.alert = []
    },
    SET_SEND_MESSAGE_MODAL (data: any) {
      this.sendMessageModal = data
    },
    SET_DISCONNECTED_TIME (data: any) {
      this.disconnectedTime = data
    },
    SET_ADMIN_DATA_CHANGE (payload: any) {
      this.adminDataChange = payload
    },
    SET_USERS (payload: any) {
      this.users = payload
    },
    SET_ADMIN_CATEGORIES (payload: any) {
      this.adminCategories = payload
    },
    SET_ADMIN_ARTICLES (payload: any) {
      this.adminArticles = payload
    },
    SET_OFFLINE_STATUS (status: any) {
      this.isOffline = status
    },
    SET_LOGOUT_CLICKED (status: any) {
      this.isLogoutClicked = status
    },
    /**
     * Set the socket connection status
     * @param {SocketConnectionStatus} status - The socket state
     */
    SET_SOCKET_CONNECTION_STATUS (status: SocketConnectionStatus) {
      this.socketConnectionStatus = status
    },
    SET_GLOBAL_THEME (theme: any) {
      Vuetify.framework.theme.dark = theme === 'dark'
      this.globalTheme = theme
    },
    SET_UNEXPECTED_ERROR (text: any) {
      this.unexpectedError = text
    },
    SET_ADMIN_DRAWER (drawer: any) {
      this.adminDrawer = drawer
    },
    /**
     * Set the socket client list
     * @param {any} clientData - The client data (client list and realtime data)
     */
    SET_CLIENT_DATA (clientData: any): void {
      this.clientList = clientData.clients;
      this.realtimeClientData = clientData.realtimeUsersData;
    },
    /**
     * Set the socket disconnect reason
     * @param {SocketDisconnectReason} status - The socket disconnect reason
     */
    SET_SOCKET_DISCONNECT_STATUS (status: SocketDisconnectReason): void {
      this.socketDisconnectReason = status
    },
    /**
     * Reset the app (disconnect from the socket if connected, reset the vuex to default state, redirect to the login page if not already there)
     */
    resetApp(): void {
      const auctionStore= useAuctionStore()
      console.log("resetApp action called")

      // Disconnect from the socket if connected
      if (this.socket) {
        if (this.socket.disconnect) { //needed for auctioneers screen and viewer screen)
          this.socket.disconnect()
        }
        if (this.socket.removeAllListeners) { //needed for auctioneers screen and viewer screen)
          this.socket.removeAllListeners()
        }
        SocketClient.removeInstance()
        console.log('socket disconnected after logout')
      } else {
        console.log('socket not disconnected as already closed from the backend')
      }

      // Update profile
      const localization = this.appLocalization
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: {} });
      auctionStore.$reset();
      this.$reset();
      this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: localization })
      const cookies = localStorage.getItem('vue-cookie-accept-decline-cookies')
      localStorage.clear()
      if (cookies != null) {
        localStorage.setItem('vue-cookie-accept-decline-cookies', cookies)
      }
      Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
      if (localFeatures.useSentry) {
        Sentry.setUser(null);
      }
      // Back to login page
      // router.push({ name: 'login' })
      if (router.currentRoute.name !== 'login') router.push({ name: 'login' })
      this.getAppSettings()
    },
    /**
     * Log the user out (delete the jwt if it exists and is valid)
     */
    async logout (): Promise<void> {
      const auctionStore = useAuctionStore()
      console.log("logout action called")
      if (this.isLogoutClicked) {
        await this.leaveAuction(auctionStore.currentAuction.id, false);
      }
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        apiRequests.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
          this.resetApp()
        })
      } else {
        removeAuthToken()
        this.resetApp()
      }

      /*
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        apiRequests.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
        })
        dispatch('resetApp')
      } else {
        removeAuthToken()
        dispatch('resetApp')
      }
      */
    },
    /**
     * Run the kickout logic, this happens if the user gets kicked out (delete the jwt,disconnect from the socket if connected, reset the vuex to default state, redirect to the login page)
     */
    async kickOut () {
      console.log("kickout action called")
      // Remove token
      removeAuthToken()

      this.resetApp()
    },
    /**
     * Get user profile from current user (updates the store)
     * @return {<any>} - The user profile or null if empty
     */
    async getCurrentUserProfile (): Promise<IUser> { // TODO use this everywhere
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result;
        if (this.appFeatures && this.appFeatures.webSocketFunctionality && this.socketConnectionStatus === SocketConnectionStatus.connected) {
          result = socketRequests.getUserProfile()
        } else {
          result = await apiRequests.getUserProfile();
        }

        // empty result
        if (_.isEmpty(result.data)) {
          return null
        }

        this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result.data.data });
        return result.data.data;
      } finally {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    // Get notification list
    async getNotification () {
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result = await apiRequests.getNotification();

        // Cant connect to backend or no internet
        if (!result) {
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          return false;
        }

        let { data } = result;

        // Error happens
        if (data.error) {
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          return false
        } else { // Success
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          this.UPDATE_GLOBAL_STATE({ key: 'notificationList', value: data.data });
          return data.data
        }
      } catch (e) {

      }
    },
    // Mark read notification
    async markReadNotification (id: number) {
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result = await apiRequests.markReadNotification(id);

        // Cant connect to backend or no internet
        if (!result) {
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          return false;
        }

        let { data } = result;

        // Error happens
        if (data.error) {
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          return false
        } else { // Success
          // Hide loading
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          return data.data
        }
      } catch (e) {

      }
    },
    /**
     * Update user profile (updates the store)
     * @return {boolean} - True if the profile has been updated, false if not due to errors
     */
    async updateUserProfile (userData: any) {
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result;
        if (this.appFeatures && this.appFeatures.webSocketFunctionality) {
          result = await socketRequests.updateUserProfile(userData.id, userData);
        } else {
          result = await apiRequests.updateUserProfile(userData);
        }

        this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result.data.data });
        return true;
      } finally {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    // Get user settings or null if there is an error (i.e. http 401)
    async getUserSettings () {
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result = await apiRequests.getUserSettings();

        let { data } = result;

        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: data.data.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: data.data.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: data.data.localization });
        // get app stream settings
        this.UPDATE_GLOBAL_STATE({ key: 'streamSettings', value: data.data.builtinStreaming });
        return data.data
      } catch (e) {
        return null
      } finally {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    // Get public settings
    async getAppSettings () {
      try {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });
        let result = await apiRequests.getAppSettings()

        let { data } = result;

        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: data.data.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: data.data.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: data.data.localization });
        return data.data
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There is a temporary error in the application. Please try again later') })
      } finally {
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
      }
    },
    // Update article data and save to local storage and cookie
    updateArticleData (payload: any) {
      const cachedData = [...JSON.parse(<string>localStorage.getItem(`${payload.auction_id}ArticleCache`))]
      const updatedData = cachedData.map(article => {
        if (article.id === payload.article.id) {
          return {
            ...article,
            ...payload.article
          }
        }
        return article
      })
      const cookiesData = {
        AuctionID: payload.auction_id,
        articleCacheLastUpdated: new Date()
      }
      this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
        auctionID: payload.auction_id,
        article: payload.article
      });
      Vue.$cookies.set(`${payload.auction_id}ArticleCache`, JSON.stringify(cookiesData))
      localStorage.setItem(`${payload.auction_id}ArticleCache`, JSON.stringify(updatedData))
    },
    // Get articles from one auction
    async getArticles (payload: any) {
      const auctionID = payload.auctionID
      try {
        const cookiesUpdateTime = Vue.$cookies.get(`${auctionID}ArticleCache`) ? moment(Vue.$cookies.get(`${auctionID}ArticleCache`).articleCacheLastUpdated) : false
        const auctionUpdateTime = payload.auction && moment(payload.auction.updated_at)
        const diffTime = cookiesUpdateTime ? cookiesUpdateTime.diff(auctionUpdateTime, 'minutes') > 0 : false
        if (!this.isAuthenticatedAsUser && diffTime && localStorage.getItem(`${auctionID}ArticleCache`)) {
          const storageArticles = [...JSON.parse(<string>localStorage.getItem(`${auctionID}ArticleCache`))]
          this.SET_AUCTIONS_FETCHED_ARTICLES({
            auctionID,
            articles: storageArticles
          });
          localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(storageArticles))
          return true
        }
        if (localStorage.getItem(`${auctionID}ArticleCache`) && Vue.$cookies.get(`${auctionID}ArticleCache`) && this.isAuthenticatedAsUser) {
          try {
            const resp = await apiRequests.getArticlesDynamic(auctionID)

            const storageArticles = [...JSON.parse(<string>localStorage.getItem(`${auctionID}ArticleCache`))]
            const articles = storageArticles.map(el => {
              const target = resp.data.data.find((article: { id: any }) => el.id === article.id)
              if (target) {
                return {
                  ...el,
                  ...target
                }
              }
              return el
            })
            this.SET_AUCTIONS_FETCHED_ARTICLES({
              auctionID,
              articles
            });
            localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(articles))
            return true;
          } catch (e: any) {
            console.log('querying dynamic article data failed, doing full transfer')
          }
        }
        if (!this.isAuthenticatedAsUser && !diffTime && localStorage.getItem(`${auctionID}ArticleCache`)) {
          console.log('auction data updated, pulling new data')
        } else {
          console.log('no cache found, doing full article sync')
        }
        let data
        if (this.isAuthenticatedAsUser) {
          const resp = await apiRequests.getArticles(auctionID)
          data = resp.data
        } else {
          const resp = await apiRequests.getGuestArticles(auctionID)
          data = resp.data
        }
        this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID, articles: data.data });

        const cookiesData = {
          AuctionID: auctionID,
          articleCacheLastUpdated: new Date()
        }
        if (!this.cookiesStatus) return
        Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify(cookiesData))
        localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(data.data))
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: i18n.t('There was an error loading the data. Please try again later')
        })
      }
    },
    // Get articles from one auction for the admin frontend
    async getAdminArticles (payload: any) {
      const auctionID = payload.auctionID
      try {
        const { data } = await apiRequests.checkIfAuctionArticlesUpdated(auctionID)
        const cacheUpdateTime = Vue.$cookies.get(`${auctionID}ArticleCache`) ? moment(Vue.$cookies.get(`${auctionID}ArticleCache`).articleCacheLastUpdated) : null
        const responseUpdateTime = !data.error && data.data.updated_at ? moment(data.data.updated_at) : null
        if (!data.error && cacheUpdateTime && responseUpdateTime && responseUpdateTime.diff(cacheUpdateTime, 'seconds') > 0) {
          const resp = await apiRequests.getArticles(auctionID)
          this.SET_AUCTIONS_FETCHED_ARTICLES({
            auctionID,
            articles: resp.data.data
          });
          localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(resp.data.data))
          Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify({ AuctionID: auctionID, articleCacheLastUpdated: new Date() }))
        } else {
          if (localStorage.getItem(`${auctionID}ArticleCache`) && Vue.$cookies.get(`${auctionID}ArticleCache`).articleCacheLastUpdated) {
            const storageArticles = [...JSON.parse(<string>localStorage.getItem(`${auctionID}ArticleCache`))]
            this.SET_AUCTIONS_FETCHED_ARTICLES({
              auctionID,
              articles: storageArticles
            });
            return true
          }
          console.log('no cache found, doing full articles sync')
          const { data } = await apiRequests.getArticles(auctionID)
          this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID, articles: data.data })

          const cookiesData = {
            AuctionID: auctionID,
            articleCacheLastUpdated: new Date()
          }
          if (!this.cookiesStatus) return
          Vue.$cookies.set(`${auctionID}ArticleCache`, JSON.stringify(cookiesData))
          localStorage.setItem(`${auctionID}ArticleCache`, JSON.stringify(data.data))
        }
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: i18n.t('There was an error loading the data. Please try again later')
        });
      }
    },
    // Get users for the admin frontend. If the cookie and the data in local storage exists, use this instead of querying the api
    // If the force flag is used, no localStorage and cookie will be used, instead the api will be queried
    async fetchUsers (force: boolean) {
      try {
        if (!force && window.localStorage.getItem('users') && Vue.$cookies.get('users')) {
          const storageUsers = [...JSON.parse(<string>localStorage.getItem('users'))]
          this.SET_USERS(storageUsers);
          return true
        }

        console.log('no cache found, doing full users sync')
        const { data } = await apiRequests.getUsers()
        this.SET_USERS(data.data);

        const cookiesData = {
          userCacheLastUpdated: new Date()
        }
        Vue.$cookies.set('users', JSON.stringify(cookiesData))
        localStorage.setItem('users', JSON.stringify(data.data.map((user: any, index: any) => ({ ...user, index: index + 1 }))))
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error loading the user data. Please try again later') });
      }
    },
    // Get categories for the admin frontend. If the cookie and the data in local storage exists, use this instead of querying the api
    // If the force flag is used, no localStorage and cookie will be used, instead the api will be queried
    async fetchAdminCategories (force: boolean) {
      try {
        if (!force && window.localStorage.getItem('categories') && Vue.$cookies.get('categories')) {
          const storageCategories = [...JSON.parse(<string>localStorage.getItem('categories'))]
          this.SET_ADMIN_CATEGORIES(storageCategories);
          return true
        }
        console.log('no cache found, doing full categories sync')
        const { data } = await apiRequests.getAllCategories()
        this.SET_ADMIN_CATEGORIES(data.data);

        const cookiesData = {
          categoriesCacheLastUpdated: new Date()
        }
        Vue.$cookies.set('categories', JSON.stringify(cookiesData))
        localStorage.setItem('categories', JSON.stringify(data.data))
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error loading the data. Please try again later') });
      }
    },
    /**
     * Get all categories and update the store
     */
    async fetchCategories () {
      let categoriesData;
      if (this.isAuthenticatedAsUser) {
        categoriesData = await apiRequests.getAllCategories()
      } else {
        categoriesData = await apiRequests.getAllGuestCategories()
      }
      const result = categoriesData.data.data
      this.SET_CATEGORIES(result)
      return result;
    },
    SET_SOCKET_DATA (data: any) {
      this.socket = data
    },
    /**
     * Join an auction (gains access to auction events on the socket)
     */
    async joinAuction (auctionId: number) {
      if (!this.auctionJoined) {
        await dispatcher.joinLiveAuction(auctionId) // exception will be handled in the join auction component
        this.UPDATE_GLOBAL_STATE({ key: 'auctionJoined', value: auctionId });
      }
    },
    /**
     * Leave the auction, makes sense only if the auction has been joined before
     */
    async leaveAuction (auctionId: number, force: boolean) {
      try {
        if (force || this.auctionJoined) await dispatcher.leaveLiveAuction(auctionId)
      } catch (e) {
        // happens if offline and the user clicks logout since there is no socket connection and api will also fail
      }
      if (this.auctionJoined) this.UPDATE_GLOBAL_STATE({key: 'auctionJoined', value: null});
    },
  }
})

export default useRootStore
