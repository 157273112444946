<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <auction-overview
      v-if="showAuctionOverview && auctions && bidderNumbers"
      style="z-index:999999999999"
      :auctions="upcomingAuctions"
      :bidder-numbers="bidderNumbers"
      :overview-key="`${overviewKey}-overview`"
      @refreshModal="refreshModal"
      @setBidderNumbers="setBidderNumbers"
    />
    <div v-if="!showAuctionOverview && appLoading && auctions && bidderNumbers && isAuthenticatedAsUser" :key="updatingKey">
      <div class="mobile-flashphoner" :class="{ safari: browser.name === 'safari' || browser.name === 'ios' || browser.os === 'iOS' }">
        <FlashPhoner
          v-if="isMobile && !showNetworkError && currentAuction.status === 'started'"
          type="mobile"
          :isFlashPhonerDisabled="isFlashPhonerDisabled" />
      </div>
      <v-row no-gutters class="auction-wrapper" v-if="ongoingArticle" :class="{ safari: browser.name === 'safari' || browser.name === 'ios' }">
        <articles-list-desktop
          v-if="(!isMobile || showArticlesList) && !showNetworkError && isMediaReady && currentAuctionArticles.length && currentAuction.status && (currentAuction.status === 'started' || currentAuction.status === 'paused')"
          :isArticleSoldOut="isArticleSoldOut"
          :bidder-numbers="bidderNumbers"
        />
        <v-col class="auction-wrapper__content">
          <v-row no-gutters>
            <!-- Mobile Version -->
            <v-col cols="12" class="article-wrapper mobile-view" style="height: 100%" sm="12" xs="12">
              <v-row class="title-wrapper" no-gutters>
                <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                  <div class="title">{{ articleTitle }}</div>
                </v-col>
                <v-row>
                  <v-col class="price-wrapper no-gutters">
                    <span class="light-title">{{ $t('Article') }}</span>&nbsp;
                    <span class="price-value">#{{ getArticleNumber }}</span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                    <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
                    <span class="price-value">
                      {{ currency }}{{ euNumbers(ongoingArticle.limit) }}
                    </span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters mr-0" v-if="ongoingArticle.estimated_price">
                    <span class="light-title">{{ $t('Estimated price') }}</span>&nbsp;
                    <span class="price-value">
                      {{ currency }}{{ ongoingArticle.estimated_price }}
                    </span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters" v-if="!isMobile && currentHighestBid && currentHighestBid.value">
                    <span class="light-title">{{ $t('Hammer price') }}</span>&nbsp;
                    <span class="price-value hammer-price">
                      {{ currency }}{{ euNumbers(currentHighestBid.value) }}
                    </span>
                  </v-col>
                  <div class="extra-spacer" v-if="!ongoingArticle.estimated_price || !ongoingArticle.estimated_price" />
                </v-row>
              </v-row>
            </v-col>
            <v-col class="image-slider-wrapper" cols="12" style="height: 100%" lg="6" md="6" sm="12" xs="12">
              <VueSlickCarousel lazyLoad="ondemand" :arrows="true" :key="articleGallery ? articleGallery.length : 0" :dots="false" class="slider-container" v-if="ongoingArticle && articleGallery && articleGallery.length">
                <template #prevArrow>
                  <div class="custom-arrow">
                    <v-icon class="arrow-left">fa-angle-left</v-icon>
                  </div>
                </template>

                <template #nextArrow>
                  <div class="custom-arrow">
                    <v-icon class="arrow-right">fa-angle-right</v-icon>
                  </div>
                </template>
                <div class="image-item" v-for="itm in articleGallery" :key="itm.articleNumber">
                  <v-img class="article-image" :contain="true" :src="itm.image" height="35vh" width="80%" />
                </div>
              </VueSlickCarousel>
              <div class="flashphoner-container-desk">
                <div class="slider-flashphoner" v-if="!isFlashPhonerDisabled && currentAuction.status === 'started'">
                  <FlashPhoner v-if="!isMobile" />
                </div>
              </div>
            </v-col>

            <v-row class="article-description mobile-view" no-gutters>
              <div>
                <span v-if="useHtml" class="useHtmlData" v-html="articleDescription"></span>
                <span v-else>{{ articleDescription }}</span>
              </div>
            </v-row>

            <v-col style="height: 100%;" class="article-wrapper" lg="6" md="6" sm="12" xs="12">
              <v-row class="title-wrapper" no-gutters>
                <v-col lg="12" md="12" sm="12" xs="12">
                  <div class="title">{{ articleTitle }}</div>
                </v-col>
                <v-row class="justify-start my-0">
                  <v-col class="price-wrapper no-gutters">
                    <span class="light-title">{{ $t('Article') }}</span>&nbsp;
                    <span class="price-value">#{{ ongoingArticle.number_optional || _.padStart(ongoingArticle.number, 4, '0') }}</span>
                  </v-col>
                  <br v-if="ongoingArticle.estimated_price">
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                    <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
                    <span class="price-value">{{ currency }}{{ euNumbers(ongoingArticle.limit) }}</span>
                  </v-col>
                  <br v-if="ongoingArticle.limit">
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price">
                    <span class="light-title">{{ $t('Estimated price') }}</span>&nbsp;
                    <span class="price-value">{{ currency }}{{ ongoingArticle.estimated_price }}</span>
                  </v-col>
                </v-row>
              </v-row>

              <v-row class="pb-6" justify="start" no-gutters align="start">
                <v-col md="6" class="current-highest-bid-block border-right" v-if="ongoingArticle.status === '2' || ongoingArticle.status === '0'" style="min-height: 128px;">
                  <p class="mb-1 current-highest">{{ $t('Current highest bid') }}</p>
                  <p class="bold-500 bid-value mb-2">{{ currency }}{{ euNumbers(currentHighestBid.value) }}</p>
                  <p v-if="isArticleSoldOut && !isMobile" class="push-text mb-0 mt-4" :class="{ 'blue-bg': userProfile.id !== currentHighestBid.id }">
                    <span v-if="userProfile.id === currentHighestBid.id">{{ $t('This item is sold out to you') }}</span>
                    <span v-else>{{ $t('This item is sold out') }}</span>
                  </p>
                  <p v-if="isLastArticleSoldOut && isArticleSoldOut" class="bold-500  sold-out-last">
                    <span>{{ $t('Last article sold out. The auction will end immediately.') }}</span>
                  </p>
                </v-col>
                <v-col md="6" v-if="ongoingArticle.status === '2' || ongoingArticle.status === '0' " class="pl-3 current-highest-bid-block notifications-block">
                  <div v-if="!isArticleSoldOut && showPushNotiTooltip && ongoingArticle.status === '2' && userProfile.id !== currentHighestBid.id" class="push-text mb-3" style="font-size: 16px;text-align:left;">
                    <div>{{ $t('The article is about to be sold.') }}</div> <b>{{ $t('Bid now!') }}</b> <v-icon style="font-size: 16px" class="warning-icon">fa-triangle-exclamation</v-icon>
                  </div>
                  <div v-if="!(isArticleSoldOut && userProfile.id === currentHighestBid.id) && userProfile.id === currentHighestBid.id" class="push-text">
                    <span v-if="currentHighestBid.type === BidType.live">{{ $t(`You're the highest bidder`) }}</span>
                    <span v-else>{{ $t(`You're the highest bidder (via commission bid)`) }}</span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="mt-4 text-align-right">
                    <v-btn
                      :class="`new-bid-btn ${$i18n.locale}`"
                      @click="doBidSocket()"
                      :disabled="isArticleSoldOut || isArticleNotReadyToBid || bidSent">
                      <v-icon class="add-new-icon">fa-circle-plus</v-icon>
                      <span>{{ $t('New Bid') }}</span>({{ currency }}{{ euNumbers(nextBidAmount) }})
                    </v-btn>
                  </div>
                </v-col>
                <v-col v-if="ongoingArticle.status === '1'" lg="6" md="6" sm="12" xs="12" class="current-highest-bid-block">
                  <div class="text-align-right">
                    <v-btn
                      :class="`new-bid-btn ${$i18n.locale}`"
                      disabled
                    >
                      <v-icon class="add-new-icon">fa-circle-plus</v-icon>
                      <span>{{ $t('New Bid') }}</span>
                    </v-btn>
                    <br>
                    <br>
                    <span style="font-size: 12px">{{ $t('Waiting for bidding to start...') }}<v-icon class="hourglass-icon rotation-animation" style="font-size: 20px">hourglass_empty</v-icon>
                    </span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="article-description" no-gutters>
                <div>
                  <div class="info-text full-width">
                    <v-icon>fa-circle-info</v-icon>
                    <p>
                      {{ $t('By clicking the button you submit a legally binding bid request') }}
                      <br/> {{ $t('Your paddle is') }} {{ ownBidderNumber }}
                      <span v-if="ownBidLimt"><br/>{{ $t('Your bid limit is') }} {{euNumbers(ownBidLimt)}} {{currency}}</span>
                    </p>
                  </div>
                  <h3 class="details-title">{{ $t('Details') }}</h3>
                  <div class="overflow-auto" :style="{ maxHeight: descriptionMaxHeight }">
                    <span v-if="useHtml" class="useHtmlData" v-html="articleDescription"></span>
                    <span v-else>{{ articleDescription }}</span>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--    Start bottom navigation bar-->
      <v-row v-if="showFooter && !showNetworkError && !showArticlesList" class="bottom-navigation-bar">
        <div class="info-text full-width">
          <v-icon>fa-circle-info</v-icon>
          <p>
            {{ $t('By clicking the button you submit a legally binding bid request') }}.
            <br />{{ $t('Your paddle is') }} {{ ownBidderNumber }}
            <span v-if="ownBidLimt"><br/>{{ $t('Your bid limit is') }} {{euNumbers(ownBidLimt)}} {{currency}}</span>
          </p>
        </div>
        <v-row class="ma-0 do-bid-block full-width">
          <v-col cols="6" class="pa-0">
            <v-row class="align-center fill-height ma-0 bidder-status-message-block">
              <v-col class="pa-1">
                <p class="mb-0" v-if="currentHighestBid && currentHighestBid.value && userProfile.id !== currentHighestBid.id && !isArticleSoldOut">
                  <span class="light-title">{{ $t('Hammer price') }}</span>&nbsp;
                  <strong>
                    {{ currency }}{{ euNumbers(currentHighestBid.value) }}
                  </strong>
                </p>
                <p v-if="!(isArticleSoldOut && userProfile.id === currentHighestBid.id) && userProfile.id === currentHighestBid.id" class="highest-price-text">
                  <span v-if="currentHighestBid.type === BidType.live">{{ $t("You're the highest bidder") }}</span>
                  <span v-else>{{ $t(`You're the highest bidder (via commission bid)`) }}</span>
                </p>
                <p v-if="isArticleSoldOut && !isMobile" class="push-text mb-0 mt-4" :class="{ 'blue-bg': userProfile.id !== currentHighestBid.id }">
                  <span v-if="userProfile.id === currentHighestBid.id">{{ $t('This item is sold out to you') }}</span>
                  <span v-else>{{ $t('This item is sold out') }}</span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn
              class="new-bid-btn"
              :class="{ 'mobile': (!isArticleNotReadyToBid && !isArticleSoldOut && userProfile.id !== currentHighestBid.id)  }"
              @click="doBidSocket()"
              :disabled="isArticleSoldOut || isArticleNotReadyToBid || bidSent"
              text>
              <span v-if="userProfile.id === currentHighestBid.id">{{ $t('Bid_highest_mobile') }} {{ currency }}{{ euNumbers(currentHighestBid.value) }}</span>
              <span v-else>{{ $t('Bid_mobile') }} {{ currency }}{{ euNumbers(nextBidAmount) }}</span>
            </v-btn>
            <v-tooltip v-model="showPushNotiTooltip" :content-class="`show-on-top hide-desktop mobile-operation-noti  ${(showPushNotiTooltip && ongoingArticle.status === '2') ? 'black-bg' : ''}`" nudge-top="20" top allow-overflow internal-activator v-if="ongoingArticle.status === '2' && userProfile.id !== currentHighestBid.id">
              <template v-slot:activator="{ on, attrs }">
                <span class="mobile-notification-cap"></span>
              </template>
              <span v-if="!isArticleSoldOut" class="push-text">
                <v-icon class="warning-icon">fa-triangle-exclamation</v-icon>
                <span style="padding-left:5px">{{ $t('The article is about to be sold.') }}</span>
                <div style="padding-left:36px">{{ $t('Bid now!') }}</div>
              </span>
              <span v-if="ongoingArticle.status === '1'" class="push-text">
                <v-icon class="hourglass-icon rotation-animation" style="font-size: 20px">hourglass_empty</v-icon>{{ $t('Waiting for bidding to start...') }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-row>
      <!--    Stop bottom navigation bar-->

      <!--    Start footer crawler-->
      <!-- <articles-overview-desktop
          @onClickArticle="selectArticle"
          v-if="renderComponent && isMediaReady && currentAuctionArticles.length"
      /> -->

      <!-- Todo : We need to change this to component -->
      <v-overlay class="custom-back-drop status-modal" :class="{ 'paused-modal': currentAuction.status === 'paused' }" v-if="renderComponent && loadingModal">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop status-modal" v-if="showNetworkError">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop no-ongoing-article-modal" v-if="currentAuction.status === 'started' && _.isEmpty(ongoingArticle)">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ $t('No ongoing article found in the live auction. Please wait') }}</span>
            </div>
          </div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SocketClient from '@/utils/socket'
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import ArticlesListDesktop from './articles-overview/articles-list-desktop'
import AuctionOverview from '@/views/dashboard-user/index'
import FlashPhoner from '@/components/flashphoner/index'
import apiRequests from '@/api/apiRequests'
import { getBidderNumbersFromUser } from '@/services/user';
import dispatcher from '@/api/dispatch';
import SocketHandlers, { SocketPostHandleEmitterEvent } from '@/api/socketHandlers';
import { detect } from 'detect-browser';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { BidType, localFeatures, SocketConnectionStatus } from '@/utils/constants';
import { euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'AuctionUser',
  components: {
    VueSlickCarousel,
    ArticlesListDesktop,
    FlashPhoner,
    AuctionOverview
  },
  async created () {
    if (this.appFeatures.clientToBackendHeartbeat) {
      console.log('client heartbeat activated')
      this.getStatusLink = setInterval(() => {
        this.getStatusEndpoint();
      }, 10000);
    }

    try {
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      await this.getAuctions()
      if (!this.isAuthenticatedAsUser) {
        this.UPDATE_GLOBAL_STATE({
          key: 'appLoading',
          value: true,
        })
        this.bidderNumbers = []
        return
      }
      let rs = await this.getCurrentActiveAuction()
      // Cant connect to server
      if (!rs || !rs.data) {
        this.CHANGE_STATE([
          {
            key: 'loadingModal',
            value: true,
          },
          {
            key: 'loadingType',
            value: 'noLive',
          }])
      }
      // Hide modal if auction is available
      if (rs.data) {
        // Get article data for slider
        console.log('get articles for active auction, auction state is', rs.data.status)
        if (this.currentAuction && this.currentAuction.id) await this.getAuctionArticles({ auctionId: this.currentAuction.id, mediaServer: this.appLocalization.media_server_url, auctionCode: this.currentAuction.code, lang: this.appStoreLang, isUserFE: true })

        if (rs.data.status === 'paused') {
          if (this.showNetworkError) {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: false,
              },
              {
                key: 'loadingType',
                value: 'networkPause',
              }])
          } else {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'paused',
              }])
          }
        }

        if (rs.data.status === 'started') {
          this.CHANGE_STATE([{
                              key: 'loadingModal',
                              value: false,
                            },
                            {
                              key: 'loadingType',
                              value: 'started',
                            }])
        }

        // console.log("rs.data.status",rs.data.status)
      }

      this.setUpSocket()

      // Listener
      /* if (this.socketConnectionStatus !== SocketConnectionStatus.init) {
        this.socket.removeAllListeners()
        this.setUpSocket()
        console.log('added socket listeners (dashboard or inside the auction)')
      } else {
        this.setUpSocket()
        this.socket.connect()
        console.log('socket connected (dashboard or inside the auction)')
      } */

      // Enable Flashphoner

      this.isFlashPhonerDisabled = this.appSettings.url_stream_type === 'stream_disabled'
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })

      // show the auction join modal if the user logged in and is on the dashboard
      if (!_.isEmpty(this.userProfile)) await this.showJoinModal()
    } catch (e) {
      // console.log(e)
      this.UPDATE_GLOBAL_STATE([
        {
          key: 'error',
          value: true,
        },
        {
          key: 'errorMessage',
          value: e.message,
        },
      ])
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    }
  },
  data: () => ({
    slideModel: null,
    items: [],
    showNotiTooltip: false, // When user got higher bid
    notificationMessage: '',
    currentMobilePage: 1,
    showMobileArticleModal: false,
    articleGallery: [],
    showNetworkError: false,
    isArticleSoldOut: false,
    isLastArticleSoldOut: false,
    connectedTime: 0,
    disconnectedTime: 0,
    connectionTimer: null,
    disconnectionTimer: null,
    disconnected: false,
    cachedCurrentArticle: null,
    renderComponent: true,
    showPushNotiTooltipTest: true,
    isFlashPhonerDisabled: false,
    auctions: null,
    pastAuctions: null,
    lastAuctionData: null,
    rcTimer: 0,
    soundUrl: require('@/assets/sold-audio.mp3'),
    updatingKey: 0,
    bidderNumbers: null,
    getStatusLink: null,
    overviewKey: 0,
    browser: detect(),
    bidSent: false,
    useHtml: localFeatures.useHtml,
    descriptionMaxHeight: '300px',
  }),
  watch: {
    async ongoingArticle() {
      if (this.currentAuction && this.currentAuction.id) {
        if (this.ongoingArticle.id !== this.cachedCurrentArticle) {
          this.cachedCurrentArticle = this.ongoingArticle.id
          this.articleGallery = this.ongoingArticle.images
        }
      }
    },
    currentAuction: {
      deep: true,
      handler() {
        this.isArticleSoldOut = (this.currentAuction && this.ongoingArticle && this.ongoingArticle.status === '0');
      }
    },
    async isOffline(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.showNetworkError = true
      } else {
        this.showNetworkError = false
        await this.getCurrentActiveAuction()
      }
      //this.$forceUpdate()
    },
    async socketConnectionStatus() {
      console.log('socket status in auction admin view:', this.socketConnectionStatus)
      if (!this.isAuthenticatedAsUser) return
      if (this.socketConnectionStatus === SocketConnectionStatus.connected) {
        this.connectedTime = 0
        this.disconnectedTime = 0
        clearTimeout(this.connectionTimer)
        clearTimeout(this.disconnectionTimer)
        this.connectedTimer()
        this.disconnected = false
      } else {
        // Some kind of disconnect status
        if (!this.disconnected) {
          this.connectedTime = 0
          clearTimeout(this.connectionTimer)
          this.disconnectedTimer()
          this.disconnected = true
        }
      }
    },
    productCataloguePredefinedValue(val) {
      if (!val) this.forceRerender()
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'loadingModal', 'loadingType', 'showPushNotiTooltip', 'currentAuctionArticles', 'joinModal', 'isAdminLogout']),
    ...mapState(useRootStore, ['showAuctionOverview', 'showArticlesList', 'userProfile', 'notificationList', 'showBottomNavigation', 'appLoading', 'productCataloguePredefinedValue', 'isLogoutClicked', 'appStoreLang', 'appSettings', 'appLocalization', 'appFeatures', 'isMobile', 'isOffline', 'isAuthenticatedAsUser', 'socket', 'socketConnectionStatus']),
    ...mapState(useAuctionStore, ['ongoingArticle', 'currentHighestBid', 'nextBidAmount', 'currentStepValue']),
    BidType() {
      return BidType
    },
    articleTitle () {
      return getTranslatedText(this.ongoingArticle, 'name', this.appStoreLang)
    },
    articleDescription () {
      return getTranslatedText(this.ongoingArticle, 'description', this.appStoreLang)
    },
    showFooter () {
      return this.isMobile && !this.showAuctionOverview && this.loadingType !== '' && this.loadingType !== 'connection' && this.loadingType !== 'paused' && this.loadingType !== 'noLive' && this.loadingType !== 'endAuction'
    },
    currency () {
      return this.appLocalization.currency
    },
    getArticleNumber() {
      return this.ongoingArticle.number_optional || this.ongoingArticle.number
    },
    isMediaReady() {
      return !!((this.userProfile.id && this.appLocalization))
    },

    isArticleNotReadyToBid() {
      const { ongoingArticle } = this.currentAuction
      if (ongoingArticle) {
        return ongoingArticle.last_ask_price === 0 || this.userProfile.id === this.currentHighestBid.id || ongoingArticle.status !== '2'
      }
      return false
    },
    upcomingAuctions() {
      return this.auctions.filter(e => {
        return (moment(e.active_until).diff(moment(), 'seconds') > 0)
      })
    },
    ownBidderNumber() {
      const bidderNumbers = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id)
      const target = bidderNumbers.find(el => el.user_status === 'unlocked' && el.type === 'live')
      return target && target.bidder_number
    },
    ownBidLimt() {
      const bidderNumbers = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id)
      const target = bidderNumbers.find(el => el.user_status === 'unlocked' && el.type === 'live')
      return target && target.bid_limit
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['CHANGE_STATE', 'getCurrentActiveAuction', 'doBid', 'doSocketBid', 'getAuctionArticles', 'SET_IS_ADMIN_LOGOUT']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT', 'UPDATE_AUCTIONS_FETCHED_ARTICLE', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'getNotification', 'markReadNotification', 'logout', 'SET_SOCKET_DATA']),
    async showJoinModal() {
      if (!this.bidderNumbers) {
        this.bidderNumbers = await getBidderNumbersFromUser(this.userProfile.id)
      }
      const target = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id)
      const fitRequirement = target.find(el => el.user_status === 'unlocked' && el.type === 'live')
      this.CHANGE_STATE({ key: 'joinModal', value: fitRequirement && this.showAuctionOverview && (this.currentAuction.status === 'started' || this.currentAuction.status === 'paused') })
    },
    async getAuctions() {
      try {
        let data
        if (this.isAuthenticatedAsUser) {
          const resp = await dispatcher.getAllAuctions()
          data = resp.data
        } else {
          const resp = await apiRequests.getGuestAuctions()
          data = resp.data
        }
        this.auctions = data.data
        this.pastAuctions = data.data.filter(el => el.status === 'paused' && el.updated_at)
        if (this.pastAuctions.length) {
          const auctions = this.bidderNumbers.map(el => this.pastAuctions.find(item => item.id === el.auction_id))
          if (auctions.length) {
            this.lastAuctionData = auctions[0]
          }
        }
      } catch (e) {
        this.error = true;
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    connectedTimer() {
      this.connectionTimer = setTimeout(() => {
        this.connectedTime += 1
        this.connectedTimer()
      }, 1000)
    },
    disconnectedTimer() {
      this.disconnectionTimer = setTimeout(() => {
        this.disconnectedTime += 1
        this.disconnectedTimer()
      }, 1000)
    },
    euNumbers,
    async getArticleGallery() {
      try {
        const { data } = await apiRequests.getArticleImages(this.currentAuction.id, this.ongoingArticle.id)
        if (!data.error) {
          return data.data
        }
        return []
      } catch (err) {
        // console.log('Gallery images does not exist')
        return []
      }
    },
    async getStatusEndpoint() {
      if (!this.isAuthenticatedAsUser) return
      try {
        const { data } = await apiRequests.getStatus()
        if (data.error === false) {
          return data.data
        }
        return []
      } catch (err) {
        return []
      }
    },
    // switchingModal(){
    //   this.changeState([
    //     {
    //       key: 'loadingModal',
    //       value: true,
    //     },
    //     {
    //       key: 'loadingType',
    //       value: 'loadingNext',
    //     }
    //   ])
    //   // Countdown to the next bid
    //   setTimeout(() => {
    //     this.changeState({
    //       key: 'loadingModal',
    //       value: false,
    //     })
    //   }, 2000)
    // },
    // Listen event from socket server
    async startAuctionPostHandleEmitterEvent(data) {
      if (this.showAuctionOverview) {
        this.auctions.forEach(auction => {
          if (auction.id === data.id) {
            auction.status = data.status
          }
        })
        // await this.showJoinModal()
        await this.getAuctionArticles({ auctionId: this.currentAuction.id, mediaServer: this.appLocalization.media_server_url, auctionCode: this.currentAuction.code, lang: this.appStoreLang, isUserFE: true })
        this.articleGallery = this.ongoingArticle.images
      } else {
        this.SET_IS_ADMIN_LOGOUT(false)
        // await this.getCurrentActiveAuction()
        // Store
        this.CHANGE_STATE({
          key: 'currentAuction',
          value: data,
        })

        await this.getAuctionArticles({ auctionId: this.currentAuction.id, mediaServer: this.appLocalization.media_server_url, auctionCode: this.currentAuction.code, lang: this.appStoreLang, isUserFE: true })
        this.articleGallery = this.ongoingArticle.images
      }
      this.CHANGE_STATE([{
                          key: 'loadingModal',
                          value: false,
                        },
                        {
                          key: 'loadingType',
                          value: 'started',
                        }
      ])
    },
    async resumeAuctionPostHandleEmitterEvent(data) {
      if (this.showAuctionOverview) {
        // await this.showJoinModal()
        this.CHANGE_STATE([{
          key: 'loadingType',
          value: 'started',
        }]);
      } else {
        this.SET_IS_ADMIN_LOGOUT(false)
      }
      this.CHANGE_STATE([{
                          key: 'loadingModal',
                          value: false,
                        },
                        {
                          key: 'loadingType',
                          value: 'resumed',
                        }
      ])
    },
    async stopOrEndAuctionPreHandleEmitterEvent(data) {
      if (_.isEmpty(this.currentAuction) && data === null) {
        this.auctions.forEach(auction => {
          if (auction.status === 'started') {
            auction.status = 'stopped'
          }
        })
      }
    },
    async pushDataUpdatePostHandleEmitterEvent(data) {
      console.log('auction/index.vue')
      console.log(`received real-time data update, user: ${data.updated_by}, entity: ${data.entity}, type: ${data.type}`)
      console.log('payload:', (data.data))
      if (data.entity === 'message' && this.appFeatures.webSocketDataPush) {
        this.SET_TEMP_ALERT(data.data)
      }
      if (this.appFeatures.webSocketDataPush && this.showAuctionOverview) {
        console.log('processing')
        if (data.entity === 'article') {
          if (!JSON.parse(localStorage.getItem(`${data.data.auction_id}ArticleCache`))) return
          const cachedData = [...JSON.parse(localStorage.getItem(`${data.data.auction_id}ArticleCache`))]
          if (cachedData) {
            if (data.type === 'update') {
              const updatedData = cachedData.map(article => {
                if (article.id === data.data.id) {
                  return {
                    ...article,
                    ...data.data
                  }
                }
                return article
              })
              this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(updatedData))
            } else if (data.type === 'delete') {
              const idx = cachedData.findIndex(el => el.id === data.data.id)
              cachedData.splice(idx, 1)
              this.REMOVE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(cachedData))
            } else if (data.type === 'create') {
              cachedData.push(data.data)
              this.ADD_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(cachedData))
            }
          }
        }
      }
    },
    async changeArticlePreHandleEmitterEvent(data) {
      this.CHANGE_STATE({
        key: 'showPushNotiTooltip',
        value: false,
      })
      this.$toast.clear()
    },
    async changeArticlePostHandleEmitterEvent(data) {
      this.forceRerender()
      // commit('UPDATE_ARTICLE_STATUS', this.ongoingArticle)
      this.CHANGE_STATE({
        key: 'loadingModal',
        value: false,
      })
    },
    async sellItemPostHandleEmitterEvent(data) {
      const idx = this.currentAuctionArticles.findIndex(el => el.id === data.id)
      const imageData = this.currentAuctionArticles[idx].image
      this.$set(this.currentAuctionArticles, idx, { ...data, image: imageData, bidder_id: this.currentHighestBid.id, live_bidder: this.currentAuction.highestBid.type === 'live' })
      if (!this.showAuctionOverview) this.playSound()
      this.isArticleSoldOut = true
      if (this.isMobile && !this.showAuctionOverview) {
        if (this.userProfile.id === this.currentHighestBid.id) {
          this.$toast.success(this.$t('This item is sold out to you'), {
            position: 'top',
            duration: 0
          })
        } else {
          this.$toast.info(this.$t('This item is sold out'), {
            position: 'top',
            duration: 0
          })
        }
      }
      // await this.getAuctionArticles(this.currentAuction.id)
      this.forceRerender()
    },
    setUpSocket () {
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      const { socket } = SocketClient.getInstance();
      const emitter = SocketHandlers.getEmitter();

      // Start auction (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);

      // Resume (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);

      // Stop (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.stopAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);

      // End (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.endAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);

      // Real-time data push (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.pushDataUpdatePostHandleEmitterEvent, this.pushDataUpdatePostHandleEmitterEvent);

      // Change article (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.changeArticlePreHandleEmitterEvent, this.changeArticlePreHandleEmitterEvent);

      // Change article (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.changeArticlePostHandleEmitterEvent, this.changeArticlePostHandleEmitterEvent);

      // Sell item (migrated to socket handlers)
      emitter.on(SocketPostHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);

      this.SET_SOCKET_DATA(socket)
    },
    async doBidSocket() {
      this.bidSent = true
      const data = {
        auctionId: this.currentAuction.id,
        articleId: this.ongoingArticle.id,
        bid: this.nextBidAmount,
        isBtr: false
      }
      if (this.appFeatures.webSocketBidding) {
        data.isUser = true
        await this.doSocketBid(data)
      } else {
        await this.doBid(data)
      }
      this.bidSent = false
    },
    getLoadingMessage() {
      if (this.showNetworkError) {
        return this.$t('auctionLoadingState.userNetworkIssue')
      }
      return this.$t('auctionLoadingState.' + this.loadingType)
    },
    /* selectArticle(index) {
      // Only can select on going and available item
      if (this.currentAuctionArticles[index].status !== '0' && this.currentAuctionArticles[index].status !== '3') {
        let element = document.getElementById('close-list-btn');
        // Fire click
        if (element) {
          element.click()
        }

        this.changeState({
          key: 'selectedArticle',
          value: index,
        })

        // User choose non-sold article
        if (this.currentAuctionArticles[index].status === '1') {
          this.changeState({
            key: 'showPushNotiTooltip',
            value: true,
          });

          // Hide after 5s
          setTimeout(() => {
            this.changeState({
              key: 'showPushNotiTooltip',
              value: false,
            })
          }, 2000)
        } else {
          this.changeState({
            key: 'showPushNotiTooltip',
            value: false,
          })
        }

        // On mobile view, hide article list modal
        this.currentMobilePage = 1
      }
    }, */

    // Hide notification tooltip
    closeNotiTooltip () {
      this.showNotiTooltip = false
    },

    // Mark notification as read
    async readNotification (id) {
      // await this.markReadNotification(id)

      // await this.getNotification()
    },

    // Switch pages in bottom panel
    switchBottomPanel (value) {
      this.currentMobilePage = value
    },
    openProfileModal() {
      this.UPDATE_GLOBAL_STATE({
        key: 'showBottomNavigation',
        value: false,
      })
      this.UPDATE_GLOBAL_STATE({
        key: 'showEditProfileModal',
        value: true,
      })
    },
    playSound () {
      const audio = new Audio(this.soundUrl);
      audio.play();
    },
    setBidderNumbers(data) {
      this.bidderNumbers = [...data]
    },
    async refreshModal() {
      this.bidderNumbers = await getBidderNumbersFromUser(this.userProfile.id)
      await this.getAuctions()
      this.overviewKey++
    },
  },
  beforeDestroy() {
    if (this.appFeatures.clientToBackendHeartbeat) clearInterval(this.getStatusLink)
    const emitter = SocketHandlers.getEmitter();
    emitter.off(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.pushDataUpdatePostHandleEmitterEvent, this.pushDataUpdatePostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.stopAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.endAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.changeArticlePreHandleEmitterEvent, this.changeArticlePreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.changeArticlePostHandleEmitterEvent, this.changeArticlePostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);
  }
}
</script>
<style lang="scss" scoped>
.auction-wrapper {
  position: relative;
  padding-top: 10px;
  &__content {
    @media screen and (min-width: 968px) {
      max-width: calc(100% - 332px);
    }
    @media screen and (min-width: 968px) and (max-width: 1300px) {
      padding-left: 340px !important;
      max-width: 100%;
    }
  }
  &.safari {
    @media(max-width: 968px) {
      padding-top: 80px;
    }
  }
}
.info-text {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 15px;
  background: #87A4CF;
  padding: 11px 24px 11px 19px;
  color: #264D86;
  border-radius: 8px;
  @media(max-width: 760px) {
    display: none;
  }
  .v-icon {
    font-size: 27px;
    color: #264D86;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
  }
}
.mobile-notification-cap {
  position: relative;
  top: -35px;
  right: -30px;
}
</style>
